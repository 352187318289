import styles from './Slider.module.css';

const Slider = ({ title, subTitle, bgImg }) => {
  return(
    <div 
      className={styles.slider} 
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: 'cover',
        height: '100vh'
      }}
    ß>
      <div className={styles.text}>
        <span>{title}</span>
        <span>{subTitle}</span>
      </div>
    </div>
  )
}

export default Slider;
