import styles from './About.module.css';
import Slider from '../../components/Slider/Slider';
import BackgroundImage from '../../images/bg1.jpeg';

const title = 'About';

export default function About() {
  return (
    <section className={styles.container}>
      <Slider title={title} bgImg={BackgroundImage} />
      <div className="container">
        <h1>About Auditech SA</h1>
        <p>Auditech SA is an international audit insurance, investment & financial consultancy company, offering customers a wide selection of financial products and services including, short and long term insurance, investment and tax consulting, investment risk management and a wide range of advisory services. We offer advice to Swiss locals and expatriates in Switzerland or those working globally.</p>
        <p>
          Our business goals are two-fold. Firstly, to build life-long relationships with our customers, built on trust, and an unwavering commitment to securing their prosperity and financial peace of mind. Secondly, to ensure our customers always happy and satisfied (wherever they are in the world).
          We aim to achieve these goals by always following 2 basic guidelines
        </p>
        <p>We’re frequently in touch with our clients, and we let them decide exactly how often they would like to review their portfolio with us.</p>
        <p>We’re always on call to address any queries and we make sure these are dealt with quickly and efficiently, ensuring we deliver the quality of service our customers deserve.</p>
        <p>What sets us apart from the rest?</p>
        <p>We are totally independent and offer clients professional and transparent financial advice. Our independent status also puts us in a position to be able to broker the most competitive insurance and investment solutions. In fact, whenever you ask us to get you a great deal on insurance or investment, we provide you with a minimum of 3 of the best quotes out there!</p>
        <p>At Auditech SA, we are truly committed to a promise of delivering great service that recognizes the unique requirements of both expats, new to Switzerland, international clients and Swiss locals alike. Our team, a dynamic mix of Swiss locals and international professionals, puts an unrivaled wealth of knowledge and experience at your disposal in a wide variety of languages.</p>
        <p>We also work closely with local partners to offer you the best in property purchasing advice, tax, investment audit, accounting and legal services.</p>
        <p>Auditech SA is registered with ZEFIX (#UID CHE-107.546.878) and with EHRA  which qualifies us to contract with all major Swiss and Global insurance companies. All client data is stored with META10 AG. Auditech SA is also an official member of the Insurance Institute of Switzerland.</p>
      </div>
    </section>
  );
}

