import styles from './Contact.module.css';
import Slider from '../../components/Slider/Slider';
import Zurich from '../../images/Zurich_Office_Picture.jpeg';
import Brussels from '../../images/Brussels_Office_Picture.png';
import BackgroundImage from '../../images/bg6.jpeg';

import classNames from 'classnames';

const title = 'Contact';

export default function Contact() {
  return (
    <section className={styles.container}>
      <Slider title={title} bgImg={BackgroundImage} />
      <div className="container">
        <div className={classNames(styles.box, "pt-5")}>
          <img src={Zurich} alt="Zurich" />
          <div className='py-3'><h2>Zurich Office:</h2> <span>Langstrasse 192, 8005 Zürich, Switzerland| tel: 41 525-083-247</span></div>
          <img src={Brussels} alt="Brussels" />
          <div className='pt-3'><h2>Brussels Office:</h2> <span>Boulevard de l’Empereur 16, 1000 Brussels, Belgium | tel: 32 480-204-084</span></div>
        </div>
      </div>
    </section>
  )
}
