
import styles from './Nav.module.css';
import { Link, useLocation } from "react-router-dom";
import LogoIcon from '../../images/logo.jpeg';

const Nav = () => {
  const { pathname } = useLocation();
  
  return (
    <section>
      <div className={styles['sub-nav']}>
        <span>+32 480-204-084 | Mon - Fri: 08.30h - 17.30h</span>
        <span className={styles.separator}>|</span>
        <span>
          <a href="mailto:info@mail.com">info@auditech-ch.co</a>
        </span>
      </div>
      <div className={styles.navbar}>
        <img src={LogoIcon} alt="logo" />
        <nav className={styles.menu}>
          <Link to="/home" className={(pathname === '/' || pathname === '/home') ? styles.active : ''}>Home</Link>
          <Link to="/about" className={pathname === '/about' ? styles.active : ''}>About</Link>
          <Link to="/insurance" className={pathname === '/insurance' ? styles.active : ''}>Insurance</Link>
          <Link to="/pension" className={pathname === '/pension' ? styles.active : ''}>Pension & Tax</Link>
          <Link to="/advisory" className={pathname === '/advisory' ? styles.active : ''}>Advisory service</Link>
          <Link to="/partners" className={pathname === '/partners' ? styles.active : ''}>Partners</Link>
          <Link to="/contact" className={pathname === '/contact' ? styles.active : ''}>Contact</Link>
        </nav>
      </div>
    </section>
  );
}

export default Nav;
