import styles from './Insurance.module.css';
import Slider from '../../components/Slider/Slider';
import BackgroundImage from '../../images/bg4.jpeg';

const title = 'Insurance';

export default function Insurance() {
  return (
    <section className={styles.container}>
    <Slider title={title} bgImg={BackgroundImage} />
    <div className="container">
      <h1>Life insurance</h1>
      <h2>“Life insurance could be the most important financial product you’ll ever buy”</h2>
      <p>If you have dependants, such as school age children, a partner who relies on your income or a family living in a home with a mortgage that you pay, a life insurance policy can provide for them in the event of you passing away. The emotional burden of losing someone is very hard to deal with but if the loss also brings with it financial hardship for those left behind, it makes matters even worse.</p>
      <p>Our advice: Do not rely on the government, your company pension schemes or other family members to take care of your family as that support will most likely be a lot less than you expect.</p>
      <p>Contact us and allow us to provide you with a life insurance analysis for you to understand what your requirements are and to find out what you perhaps have in place already.</p>

      <h1>Personal liability insurance</h1>
      <h2>“Though not mandatory, we strongly suggest that you take out this peace of mind cover“</h2>
      <p>Liability insurance covers you in the event that you or those living in your household (and that includes your beloved pets) unintentionally cause loss or damage to a third party. You then have the obligation to compensate the incurred loss.</p>
      <p>Classic examples: Your dog attacks someone, your child breaks a neighbours window whilst playing, a flowerpot falls from your balcony and damages a car parked below or you accidently knock over a lamp in your hotel room!</p>
      <p>The liability insurance is valid worldwide and covers:</p>
      <p>Personal injury and damage to property</p>
      <p>Loss of income or assets due to personal injury or damage to third party property</p>
      <p>Defending unjustified claims</p>
      <p>If you are renting your home, then this insurance is highly recommended and will protect your rental deposit against unwarranted claims by landlords. Damage that you have unintentionally caused to a rented home will be covered, whereas wear and tear are not.</p>
      <p>Speak to us to arrange the appropriate cover for you and your household.</p>

      <h1>Investment insurance</h1>
      <h2>“You work hard for what you own. Protect it”</h2>
      <p>Anything that you have invested and is in your investment portfolio is considered as your investment. Shares, broker investments, any assets for your future. Protect your investments against non-leggit brokers, scams or fraud.</p>
      <p>Additional options are also available. For example, the audit of your investment portfolio, audit of your broker, cover from a scam , fraud or illegal activity of third parties. Loss of assets even if caused by your own fault.</p>
      <p>Speak to us to ensure that you choose a company with a straightforward and uncomplicated approach in the protection of your assets.</p>

      <h1>Legal insurance</h1>
      <p>In your private capacity, there may be situations where you have a run-in with the law; a fight about the laundry room with your neighbor or the online purchase that you made is not delivered. All very frustrating and even more frustrating if you are left with no choice but to speak to a lawyer. At this point you will understand why it’s good to have someone in your corner!</p>
      <p>Most legal insurances cover:</p>
      <p>Legal, court and expertise costs and litigation costs</p>
      <p>Advance payments for bail, so a pre-trial detention can be avoided</p>
      <p>Support in compensation claims, criminal proceedings, and support where there are differences with government or private insurance or disputes with landlords, etc.</p>
      <p>Important to remember: One hour of legal costs can set you back as much as an entire year of insurance premiums!</p>
    </div>
  </section>
  )
}

