import styles from './Footer.module.css';
import { Link } from "react-router-dom";
import LogoIcon from '../../images/logo.jpeg';

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className='container'>
        <div className={styles.wrapper}>
          <div className={styles.section}>
            <h6>Navigation</h6>
            <ul>          
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/insurance">Insurance</Link></li>
              <li><Link to="/pension">Pension & Tax</Link></li>
              <li><Link to="/advisory">Advisory service</Link></li>
              <li><Link to="/partners">Partners</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
          <div className={styles.section}>
            <h6>Contacts</h6>
            <ul className={styles.contacts}>          
              <li><b>Zurich Office:</b> Langstrasse 192, 8005 Zürich, Switzerland | tel: 41 525-083-247</li>
              <li><b>Brussels Office:</b> Boulevard de l’Empereur 16, 1000 Brussels, Belgium | tel: 32 480-204-084</li>
            </ul>
          </div>
          <div className={styles.section}>
            <img src={LogoIcon} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  )
}
