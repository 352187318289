import styles from './Pension.module.css';
import Slider from '../../components/Slider/Slider';
import BackgroundImage from '../../images/bg2.jpeg';

const title = 'Pension & Tax';

export default function Pension() {
  return (
    <section className={styles.container}>
    <Slider title={title} bgImg={BackgroundImage} />
    <div className="container">
    <h1>Retirement planning</h1>
    <h2>“A stitch in time saves nine” – English Proverb</h2>
    <p>Each day we are confronted with new facts and the results of new studies. We need to find ways to process these facts. One of the facts is the change in life expectancy. Yes, the studies seem to indicate that we will live longer. Though it’s a good thought that the advances in medicine and the available procedures will allow us to live longer and healthy lives, the other side of longer living may cause distress for those uncertain of the financial implications of this longer life!</p>
    <p>Good planning and obtaining an overview will give you the tools required to take control of your overall financial situation, thereby allowing you to take steps in the direction that you feel comfortable with and taking away some apprehension about the future. What is involved? Depending on your expectations, we will provide you with an overview of what you can expect from any state pensions, company pension and monies you have privately saved up. We can also provide you with a complete insurance overview, as well as provide guidance when it comes to questions on inheritance, tax optimising and ways for you to invest towards retirement.</p>
    <p>Get the ball rolling today…. speak to us and become informed!</p>

    <h1>QROPS (Qualifying Recognised Overseas Pension Scheme)</h1>
    <p>Do you qualify?</p>
    <p>QROPS, HMRC recognized overseas pension schemes should be considered by expatriates and individuals who have worked in the United Kingdom as a QROPS allows for the transfer of the UK pension to other jurisdictions. These jurisdictions should be carefully selected and we suggest, not only by the choosing the one with the greatest tax advantage, but to check that the jurisdiction also offers consumer protection and regulated safeguards.</p>
    <p>QROPS provide certain advantages over other types of pension schemes. Here, one has the choice of currency, age of retirement (can be 55), flexible income drawdown, investment flexibility, lump sum accessibility and the opportunity to consolidate pensions.</p>
    <p>However, we suggest that you speak to our qualified consultants to see if you would qualify, and if yes, to obtain an independent overview of the best course of action for your situation.</p>

    <h1>SIPP</h1>
    <p>What is a SIPP?</p>
    <p>A SIPP (Self Invested Personal Pension) is a UK government-approved personal pension plan that gives you the freedom and flexibility to choose how to manage your own pension funds. An increasing number of people are now moving their pension plans into a SIPP for a variety of compelling reasons.</p>
    <p>Benefits of choosing a SIPP</p>
    <p>Nobody understands your retirement aspirations better than you do. That’s why it’s so important that you have a say in how your pension funds are managed. Not only does a SIPP give you that say, but it also empowers you to take absolute control over your future retirement funding.</p>
    <p>A SIPP (as opposed to a classic pension) offers:</p>
    <p>Simplification and control through consolidation.</p>
    <p>Flexibility on drawdowns – enables, among other benefits, a possibility for tax planning.</p>
    <p>A personalized and flexible investment profile.</p>
    <p>For absolute clarity on how you will benefit from a SIPP in your own unique situation, it’s essential to first get a complete understanding of what kind of pensions plans you currently have, and what exactly it is that they provide.</p>
    <p>What We Offer</p>
    <p>The smart approach: Let our experienced advisers do the hard work for you.</p>
    <p>Our advisers provide the optimal approach to your SIPP in a simple, three step process:</p>
    <p>Help you understand the benefits and pitfalls of your existing UK pension schemes.</p>
    <p>Provide motivated recommendations for suitable alternatives that would be the most beneficial to you.</p>
    <p>Choosing and implementing.</p>
    <p>The goal is always to make sure that you get the best possible outcome at retirement. To this end, we work to always provide you with the most appropriate solution for your UK pension, and ensure that the solution is meticulously tailored to suit your unique situation.</p>
    <p>With our focused approach to helping you achieve the ideal future financial situation, you’re freed up to spend more time on the things in life that are important to you.</p>
    <p>Simplicity</p>
    <p>Makes managing your SIPP simple through consolidation and our ongoing support.</p>
    <p>Flexibility</p>
    <p>Greater flexibility through flexible drawdowns and investment choices.</p>
    <p>Control</p>
    <p>Maximum control through insight and involvement.</p>
    <p>Our policy on the pension advice we offer you is simple – facts are what matter. For this reason, you can be assured that you will always receive the best possible advice from us – advice based on solid research, extensive expertise and careful consideration of what makes you unique.</p>
    <p>Your Ideal SIPP Partner in Switzerland</p>
    <p>We live in an age where change is a constant, yet we often don’t have the time nor the expertise to make sense of how these changes will impact us.</p>
    <p>Unlike classic pension providers, Auditech SA offers you PEACE:</p>
    <p>Partnership: You will find that we care about you and work to improve your future situation, as true partners should. </p>
    <p>Expertise: You can relax and enjoy life as you live it, while we keep track of relevant changes for you.Accessibility: You’ll get to know us on a personal level, and we’ll always be available to talk when you need us.</p>
    <p>Clarity: You’re kept abreast of what you have and how it develops.Efficiency: You don’t waste unnecessary time or energy, as we do the hard work for you and make sure that the right steps are taken in the right order.</p>
    <p>When working with us you can be sure that you’ll be getting the best advice and support to guide you through the process of establishing and managing your SIPP. Our advice is built not only on keeping you abreast of relevant developments in the UK pension industry, but also on ensuring that you have a complete understanding of the options available to you. The bottom line: you get real peace of mind within an important area of your future financial security.</p>
    <p>We also take the time to provide clarity on details that are relevant to your personal situation, the underlying forces that may affect your pension, and of course, on any aspect of your SIPP you may feel uncertain about.</p>
    
    <h1>Tax Advice</h1>
    <p>Need expert assistance with your taxes? We’re committed to providing reliable, cost-effective tax services to private individuals, and to businesses of any size. When working with us, you can be assured of excellent service and peace of mind, knowing that your tax matters are taken care of by experts who have many years of experience.</p>
    <p>Services We Offer</p>
    <p>Tax consulting</p>
    <p>Tax planning support</p>
    <p>Preparation & submission of tax returns</p>
    <p>Preparation of extension requests on your behalf</p>
    <p>Tax optimization that provides the lowest level of taxation possible</p>
    <p>Preparing & Submitting Your Tax Returns</p>
    <p>Filing your annual tax return is time-consuming and can be stressful. It can also be confusing if your finances are particularly complicated or if you have more than one source of income.  But because it’s an unavoidable part of life, and there are potential risks to submitting incorrectly, it’s certainly worth talking to an expert who will guide you through the process.</p>
    <p>We can help you complete your tax return accurately and on time – OR you can take a well-deserved break, relax and let one of our tax experts complete and submit it for you!</p>
    <p>How you benefit:</p>
    <p>Relax, knowing your Swiss tax returns will be submitted on time and in full.</p>
    <p>Save yourself valuable time and energy.</p>
    <p>Put your mind at ease, with submissions that are guaranteed to be accurate and compliant.</p>
    <p>Ensure you only pay what you have to pay with tax saving strategies in line with the latest tax guidelines.</p>
    <p>Need an extension on your tax return deadline? We’ll gladly assist you with the application, so that you get the time you need to submit your tax return accurately, or to account for any unforeseen circumstances.</p>
    
    <h1>Vested-benefits-solutions</h1>
    <p>I’m leaving Switzerland, I lost my job or I am simply taking a break from work. What’s going to happen to my pension fund assets?</p>
    <p>One thing is for sure, you will need to do something with your saved pillar 2 funds. With a wide variety of investment possibilities at your disposal, carefully considered planning is essential.</p>
    <p>Situations that call for a vested benefits solution:</p>
    <p>Planning an early retirement.</p>
    <p>Taking up self-employment.</p>
    <p>Giving up work in Switzerland and moving abroad.</p>
    <p>Taking parental leave, embarking on further training, or in the case of unexpectedly becoming unemployed.</p>
    <p>What We Offer</p>
    <p>We assist you with tailoring the exit management of your pillar 2 funds through the use of the most appropriate vested benefits solution for your situation. Whenever your professional activity in Switzerland is interrupted or stops, we can help you to take full advantage of a range of attractive benefits that are on offer – such as:</p>
    <p>Keep as much of your pillar 2 funds as possible</p>
    <p>Your transferred pension funds always land where you want them to.</p>
    <p>Your risk profile is individualized and tailored to your unique requirements.</p>
    <p>The number of parties you need to work with is reduced to one, saving you time and paperwork.</p>
    <p>Peace of mind when working with well-established Swiss financial institutions such as: Swiss Life; Liberty Pension Foundation; PensExpert; UBS; Credit Suisse; and others.</p>
    <p>Taxes are kept to a bare minimum with the most tax efficient exit possible.</p>
    <p>Our advisers also provide extensive support and will guide you through the possibilities and limitations associated with transferring your pension savings.</p>
    <p>Capital Tax Savings Example: Canton Schwyz</p>
    <p>The Jones family moves back to Australia. The husband, Mark (age 45) who worked for 10 years at a pharmaceutical company in Zürich, Switzerland, has a tidy sum of money put away in his pillar 2 pension.</p>
    <p>He would like to keep his pillar 2 funds in Switzerland until he is 65 because he likes the fact that he doesn’t need to exchange Swiss Francs to Australian Dollars, and wants to avoid any currency risks.
He also, quite rightly, sees Switzerland as one of the safest financial countries in the world.</p>
    <p>His total vested benefits capital is CHF 500,000.</p>
    <p>The tax savings implications, based upon the choice of the withdrawal destination of the pillar 2 funds, are illustrated in the below example which is based on 2018 tax figures:</p>
    <p>Mark’s vested benefits capital amount:</p>
    <p>CHF 500,000.00</p>
    <p>Withholding tax in Canton Zurich:</p>
    <p>CHF 40,325.00</p>
    <p>vs.</p>
    <p>Withholding tax in Canton Schwyz:</p>
    <p>CHF 22,825.00</p>
    <p>Pension fund payout in Canton Zurich (without deductions):</p>
    <p>CHF 459,675.00</p>
    <p>Pension fund payout in Canton Schwyz (without deductions):</p>
    <p>CHF 477,175.00</p>
    <p>Mark’s savings when using the more favourable withdrawal destination:</p>
    <p>CHF 17,500</p>
  </div>
  </section>
  )
}

