import { Routes, Route } from "react-router-dom";
import "./App.css";
import Nav from './components/Nav/Nav';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Insurance from './pages/Insurance/Insurance';
import Pension from './pages/Pension/Pension';
import Advisory from './pages/Advisory/Advisory';
import Partners from './pages/Partners/Partners';
import Contact from './pages/Contact/Contact';
import Footer from "./components/Footer/Footer";

export default function App() {
  return (
    <div>
      <div className="container"><Nav /></div>      
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="insurance" element={<Insurance />} />
        <Route path="pension" element={<Pension />} />
        <Route path="advisory" element={<Advisory />} />
        <Route path="partners" element={<Partners />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}
