import styles from './Home.module.css';
import Slider from '../../components/Slider/Slider';
import BackgroundImage from '../../images/home.jpeg';

const title = 'Is your current financial landscape unclear?';
const subTitle = 'Allow us to clear your concerns and develop a way forward';

export default function Home() {
  return (
    <section className={styles.container}>
      <Slider title={title} subTitle={subTitle} bgImg={BackgroundImage} />
      <div className="container">
        <h1>Welcome to Auditech SA</h1>
        <p>Auditech SA – international audit insurance, investment & financial consultancy company based in Zurich, Brussels and Vevey. Our team is a mix of Swiss Locals and International expats. You will receive the best advice from qualified advisors with years of experience. For independent advice look no further. We offer advice to Swiss locals and expatriates in Switzerland or those working globally.</p>
        <p>Being independent allows us to recommend the best investment solutions to our client’s individual situation. Solutions are only offered after we have discussed our client’s requirements in detail and agree the best course of action forward. Our gold standard is that we present three different quotes to any quote requested by a client.</p>
        <p>Where it comes to specialist advice, we work with best local professionals and will bring them on board if necessary.</p>
        <p>With our mix of local and expat professionals, we understand the requirements of those new to Switzerland as well as the requirements of the locals. We deal with providers and worldwide authorities on your behalf. Giving you peace of mind.</p>
        <p>Auditech SA is registered with ZEFIX (#UID CHE-107.546.878) and with EHRA, which qualifies us to contract with all major Swiss and Global insurance companies. All client data is stored with META10 AG. Auditech Audit & Consulting is also an official member of the Insurance Institute of Switzerland.</p>
      </div>
    </section>
  )
}

  