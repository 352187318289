import styles from './Advisory.module.css';
import Slider from '../../components/Slider/Slider';
import BackgroundImage from '../../images/bg3.jpeg';

const title = 'Advisory service';

export default function Advisory() {
  return (
    <section className={styles.container}>
      <Slider title={title} bgImg={BackgroundImage} />
      <div className="container">
        <h1>Property Purchasing Advice</h1>
        <h2>Services We Offer</h2>
        <div className={styles.wrapper}>
          <div>Buying & selling Consulting</div>
          <div> Support Second opinion</div>
          <div>Valuation Investigations, analyses</div>
          <div>Project development</div>
        </div>
        <p>The dream of having one’s own four walls is as old as mankind itself. Creating a home means more than simply finding a place where you can feel safe and relaxed. A true home is somewhere that gives you independence and freedom! Many people are discouraged from realising this dream by the great burden they think it involves. But it is worth stopping to take a good, hard, professional look at your situation! We can give you a good overview of the property market in our area or put you in touch with a local expert.</p>
        <p>In essence: Does buying a property in Switzerland make sense for your situation? Speak to us, we are happy to listen!</p>

        <h1>Mortgages</h1>
        <p>Financing basics</p>
        <p>Most of us cannot buy our dream properties cash…. so, we require a mortgage. Financial institutions all have their own internal regulations but most will grant a mortgage based on the market value, so in certain areas, where there is a danger that property valuations are inflated, financial institutions will possibly lower their commitment, thereby reducing their loan to value.</p>
        <p>The basic home financing deal is calculated on the following basis:</p>
        <p>20% down payment of the property value (minimum 10% cash and the rest may be taken from the pension fund).</p>
        <p>80% mortgage of which 65% of this represents the first mortgage and does not need to be repaid (depends on your financial situation at retirement).</p>
        <p>Second mortgage, which represents 15%. This mortgage needs to be repaid (*directly or indirectly) over 15 years.</p>
        <p>What can I afford?</p>
        <p>In essence, the below calculation is used by the financial institutions, using inflated interest rates (if interest rates increase) and commonly known as the stress test. The below represent yearly costs:</p>
        <p>1% of the purchase price (ancillary and maintenance costs like heating, warm water, replacement of windows, painting of the facade, etc.)</p>
        <p>5% interest rate of the mortgage</p>
        <p>Repayment over 15 years of the second mortgage capital (amortisation).</p>
        <p>If the above costs are lower than 33.3% of the gross annual salary, then a mortgage may be granted.</p>
        <p>* Direct versus indirect amortisation</p>
        <p>Direct. With direct amortisation, you pay back your mortgage to the financial institution in regular instalments. The mortgage debt is thus steadily reduced as are the interest rate payments. However, your tax liability also increases as you can make fewer tax deductions.</p>
        <p>Indirect. With indirect amortisation, you invest into a pension savings account or life insurance policy under pillar 3a/b. The mortgage thus remains at the same level for the full duration allowing you to make ongoing tax deductions of the mortgage interest and the savings can be used to repay the second (and parts of the first) at the end of the period.</p>

        <h1>Summary</h1>
        <p>We suggest that the process of buying your dream home be used to take stock and to obtain a financial overview. Questions that should be answered:</p>
        <p>Will my home be affordable at retirement?</p>
        <p>What effect would the loss of the main income earner have for the rest of the family?</p>
        <p>Overall tax implication</p>
        <p>Come speak to us. We will guide you through the process and seek a suitable financing partner in line with your financial requirements.</p>

        <h1>Investments</h1>
        <p>The world of investments may indeed seem complex! But then again, your savings account brings you just about nothing or losses so it’s quite normal for you to find ways for you to find a better return for your hard-earned income. Come and speak to us! We can assist you.</p>
        <p>To start, it’s important for us to understand your requirements, your investment profile & horizon of your investment. Then, in line with your requirements, we will together find a solution tailored to your goals or will help you fix your investment fails. For many clients, having access to investment and security institutions across overseas jurisdictions, in various fiat and crypto currencies and across the spectrum of securities is self-explanatory. Let us assist you to find which investments are suitable, be it regular investments or lump-sum investments with the highest level of security and insurance.</p>
      </div>
    </section>
  );
}
