import { useEffect, useState } from 'react';
import styles from './Partners.module.css';
import Slider from '../../components/Slider/Slider';
import BackgroundImage from '../../images/bg5.jpeg';

const title = 'Partners';

export default function Partners() {
  const [images, setImages] = useState([]);

  const importAll = (r) => {
    return r.keys().map(r);
  }

  useEffect(() => {
    setImages(importAll(require.context('../../images/partners/', false, /\.(png|jpe?g|svg)$/)));
  }, []);

  return (
    <section className={styles.container}>
      <Slider title={title} bgImg={BackgroundImage} />
      <div className="container">
        <h1>Our partners and product providers</h1>
        <p>We are pleased to work with the following partners and product providers.</p>
        <div className={styles.partners}>
        {images.map((image, index) => <img key={index} src={image} alt="partner"></img>)}
        </div>
      </div>
    </section>
  )
}

